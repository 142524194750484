// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Home = (props: PageProps) => (
  <div>
    <SEO title="Page two" />
    <section
  data-section-id={2}
  data-share=""
  data-category="headers"
  data-component-id="44d0b9e2_02_awz"
  className="relative pb-20 bg-gray-800 overflow-hidden"
>
  <div className="absolute bottom-0 inset-x-0 h-full bg-gradient-zospace-1" />
  <div className="absolute bottom-0 inset-x-0 h-3/5 w-2full -ml-64 -mb-12 bg-gradient-zospace-2 transform -rotate-6" />
  <nav
    className="relative"
    data-config-id="toggle-mobile"
    data-config-target=".navbar-menu"
    data-config-class="hidden"
  >
    <div className="flex py-8 px-4 lg:px-8 justify-between items-center">
      <button className="p-2 navbar-burger">
        <svg
          className="w-10 h-3"
          width={39}
          height={13}
          viewBox="0 0 39 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-config-id="auto-svg-1-2"
        >
          <rect width={39} height={2} rx={1} fill="#C4C4C4" />
          <rect x={19} y={11} width={20} height={2} rx={1} fill="#C4C4C4" />
        </svg>
      </button>
      <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
        <a
          className="text-2xl text-white font-bold"
          href="#"
          data-config-id="brand"
        >


          {/** logo  */}
          <StaticImage
            className="h-7"
            src="../images/zospace-logo.svg"
            alt="A dinosaur"
            placeholder="blurred"
            layout="fixed"
            //width={200}
            //height={200}
          />



        </a>
      </div>
      <div className="hidden lg:block">
        <a
          className="inline-block mr-10 text-lg text-white hover:text-gray-50 font-bold border-b border-gray-200"
          href="#"
          data-config-id="secondary-action"
        >
          Try for free!
        </a>
        <a
          className="inline-block px-12 py-4 text-white font-bold border border-gray-200 hover:border-white rounded-full"
          href="#"
          data-config-id="primary-action"
        >
          Sign Up
        </a>
      </div>
    </div>
  </nav>
  <div className="relative container px-4 pt-12 md:pt-20 mx-auto">
    <div className="hidden lg:block absolute bottom-0 w-3/5 mb-48 2xl:mb-72 h-2 border-b border-white" />
    <div className="relative flex flex-wrap -mx-4 items-start">
      <div className="w-full lg:w-1/2 2xl:w-2/5 px-4 mb-20 mb:mb-0">
        <span
          className="text-xl lg:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-yellow-200 to-orange-600"
          data-config-id="label"
        >
          Two ways to start!
        </span>
        <h2
          className="max-w-lg lg:max-w-md 2xl:max-w-none mt-7 mb-12 mb:mb-20 text-6xl lg:text-7xl 2xl:text-9xl text-white font-bold font-heading"
          data-config-id="header"
        >
          Get perfect app for your project
        </h2>
        <p
          className="mb-12 lg:mb-24 text-lg text-white opacity-90"
          data-config-id="desc"
        >
          Discover how it works and start for free.
        </p>
        <div className="mb-12 lg:mb-40">
          <a
            className="inline-block px-12 py-5 text-lg text-white font-bold bg-blue-500 hover:bg-blue-600 rounded-full transition duration-200"
            href="#"
            data-config-id="hero-primary-action"
          >
            Get started
          </a>
        </div>
        <div className="flex flex-wrap">
          <a className="mb-3 sm:mb-0 mr-4" href="#">


          <StaticImage
            className="h-16 xl:h-20"
            src="../images/apple-button.svg"
            alt="A dinosaur"
            placeholder="blurred"
            layout="fixed"
            data-config-id="auto-img-1"
            //width={200}
            //height={200}
          />

          </a>
          <a href="#">

          <StaticImage
            className="h-16 xl:h-20"
            src="../images/google-button.svg"

            alt="A dinosaur"
            placeholder="blurred"
            layout="fixed"
            data-config-id="auto-img-2"
            //width={200}
            //height={200}
          />

          </a>
        </div>
      </div>
      <div className="w-full lg:w-1/2 2xl:w-3/5 px-4">

          <StaticImage
            className="w-full h-full object-contain"
            data-config-id="image2"
            src="../images/two-phones2.png"
            alt="A dinosaur"
            placeholder="blurred"
           // layout="fluid"
            //width={200}
           // height={200}
          />

      </div>
    </div>
  </div>
  <div
    className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50"
    data-config-id="toggle-mobile-2"
    data-config-target=".navbar-menu"
    data-config-class="hidden"
  >
    <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80" />
    <nav className="relative flex flex-col py-8 h-full w-full bg-white overflow-y-auto">
      <div className="flex items-center mb-16 pr-6">
        <a
          className="ml-10 mr-auto text-2xl text-gray-800 font-bold"
          href="#"
          data-config-id="brand"
        >

          <StaticImage
            className="h-7"
            src="../images/zospace-dark-logo.svg"
            alt="A dinosaur"
            placeholder="blurred"
            layout="fixed"
            //width={200}
            //height={200}
          />


        </a>
      </div>
      <div>
        <ul>
          <li className="mb-1 px-10">
            <a
              className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl"
              href="#"
              data-config-id="link1"
            >
              Product
            </a>
          </li>
          <li className="mb-1 px-10">
            <a
              className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl"
              href="#"
              data-config-id="link2"
            >
              Story
            </a>
          </li>
          <li className="mb-1 px-10">
            <a
              className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl"
              href="#"
              data-config-id="link3"
            >
              Features
            </a>
          </li>
          <li className="mb-1 px-10">
            <a
              className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl"
              href="#"
              data-config-id="link4"
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
      <div className="mt-auto px-10">
        <div className="pt-6">
          <a
            className="block mb-4 py-4 px-12 text-gray-800 text-center font-bold border border-gray-50 hover:border-gray-100 rounded-full"
            href="#"
            data-config-id="secondary-action"
          >
            Sign in
          </a>
          <a
            className="block py-4 px-12 text-white text-center font-bold bg-blue-500 hover:bg-blue-600 rounded-full transition duration-200"
            href="#"
            data-config-id="primary-action"
          >
            Sign up
          </a>
        </div>
        <p className="mt-6 mb-4 text-lg text-center">
          <span data-config-id="copy">
            2021 © Zospace. All rights reserved.
          </span>
        </p>
      </div>
    </nav>
  </div>
</section>

  </div>
)

export default Home
